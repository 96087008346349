/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: Roboto, droid-serif;
  overscroll-behavior: none;
}

.overlaySpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 0;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 101; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* .mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

mat-toolbar {
  min-height: 48px;
  max-height: 48px;
} */

.container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
/*-----------*/
.container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: space-evenly;
}
.item {
  margin: 5px;
}
